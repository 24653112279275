@import './animations';
@import './typography';

.card-input {
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: column;

  label {
    @extend .f-14;
    font-weight: 500;
    color: var(--text-color);
  }

  &.radio-button {
    mat-radio-group {
      padding: 12px 16px 6px 0;
      display: flex;
      gap: 24px;

      .mat-radio-button {
        label {
          color: var(--main-color);
        }
      }
      .mat-radio-outer-circle {
        border-width: 3px;
      }

      .mat-radio-outer-circle {
        border-color: var(--main-color);
      }
      .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
        border-color: var(--main-color);
      }

      .mat-radio-button.mat-accent.mat-radio-checked
        .mat-radio-persistent-ripple,
      .mat-radio-inner-circle,
      .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
      &.mat-radio-checked .mat-radio-persistent-ripple,
      &:active .mat-radio-persistent-ripple {
        border-color: var(--text-color);
        background-color: var(--text-color);
      }
    }
  }

  .input-icon {
    position: relative;
    width: 100%;
    input {
      width: 100%;
      padding-right: 2rem;
    }
    button {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 0px;
      color: var(--main-color);
      cursor: pointer;
    }
  }

  &.input-search {
    position: relative;
    width: 100%;
    input {
      width: 100%;
      padding-left: 2rem;
    }
    button {
      position: absolute;
      top: 50%;
      transform: translateY(-53%);
      left: 0px;
      color: var(--main-color);
      cursor: pointer;
    }
  }

  input,
  textarea,
  select {
    @extend .f-16;
    padding: 12px 16px 6px 0;
    border: 0 solid;
    border-color: transparent;
    border-bottom: var(--border);
    background-color: transparent;
    outline: none;
    color: var(--main-color);

    &::placeholder {
      color: #9a9a9a;
    }

    &[type='number'] {
      -webkit-appearance: textfield;
      -moz-appearance: textfield;
      appearance: textfield;
    }
    &[type='number']::-webkit-inner-spin-button,
    &[type='number']::-webkit-outer-spin-button {
      -webkit-appearance: none;
    }
    &.ng-touched.ng-invalid {
      border-color: var(--red-color);
      @include shake;
    }
    &[type='file'] {
      display: none;
    }
  }

  .mat-datepicker-toggle {
    position: absolute;
    right: 5px;
  }

  .mat-button-wrapper {
    color: var(--text-color);
  }

  mat-checkbox {
    color: var(--main-color);
  }
  .mat-button {
    height: 41px;
  }

  & + .card-input {
    margin-top: 1rem;
  }
}

/* SELECTS */
.card-select {
  mat-select {
    width: max-content;
    // min-width: 90px !important;
    background-color: transparent;
    color: var(--main-color);
    @extend .f-14;
  }

  .select {
    .mat-select-value {
      color: var(--text-color);
      font-weight: 600;
    }
  }
  .mat-select-value {
    color: var(--main-color);
    font-weight: 600;
  }

  .mat-select-arrow {
    color: var(--main-color);
  }

  .mat-form-field-infix {
    display: block;
    position: relative;
    flex: auto;
    min-width: 0;
    width: 100%;
  }

  .mat-form-field-appearance-legacy {
    .mat-form-field-label {
      color: var(--main-color);
    }
    .mat-form-field-underline {
      height: 0px;
    }
  }

  .mat-form-field.mat-focused {
    &.mat-primary {
      .mat-select-arrow {
        color: var(--main-color);
      }
    }

    .mat-form-field-ripple {
      background-color: transparent;
    }
  }

  .mat-form-field-hided-placeholer .mat-select-placeholder {
    @extend .f-12;
    font-weight: 600;
  }

  .mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float
    .mat-form-field-label {
    padding-left: 5px;
  }
}

.mat-datepicker-content-container {
  background-color: white;
}
