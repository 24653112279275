@use '@angular/material' as mat;
@include mat.core();

$my-primary: mat.define-palette(mat.$indigo-palette, 500);
$my-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);
$my-theme: mat.define-light-theme(
  (
    color: (
      primary: $my-primary,
      accent: $my-accent,
    ),
    density: 0,
  )
);
@include mat.all-component-themes($my-theme);

@import 'node_modules/angular-notifier/styles';
@import 'styles/animations';
@import 'styles/button';
@import 'styles/date-picker';
@import 'styles/dialog';
@import 'styles/input';
@import 'styles/media';
@import 'styles/scroll';
@import 'styles/skeleton';
@import 'styles/tablet';
@import 'styles/progress-bar';
@import 'styles/transitions';
@import 'styles/typography';
@import 'styles/variables';

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  z-index: 5;
}

html,
body {
  height: 100%;
  background-color: var(--primary-bg);
  z-index: 1;
}
body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}

main {
  &.maxWidth {
    width: 90%;
    margin: 0 auto;
    max-width: var(--max-width);
  }
}

.cdk-global-overlay-wrapper,
.cdk-overlay-container {
  z-index: 10000000 !important;
}

.svg-inline--fa {
  display: inline-block;
  height: 1em;
  overflow: visible;
  vertical-align: -0.125em;
}

.elipse {
  width: 75vw;
  height: 75vw;
  max-width: 250px;
  max-height: 250px;
  border-radius: 50%;

  background: #595959;
  filter: blur(300px);
}
