::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-thumb {
  background: var(--main-color);
  border-radius: 6px;
}

::-webkit-scrollbar-track {
  background: var(--text-color);
  border-radius: 6px;
}
